import React from 'react';
import {useMatches, Await, useRouteLoaderData} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperClass} from 'swiper/types';
import {Autoplay} from 'swiper';
import 'swiper/swiper.min.css';
import clsx from 'clsx';
import {Skeleton} from 'components/Skeleton';

interface FacebookCustomerReview {
  authorName: string;
  authorUrl: string;
  profilePhotoUrl: string;
  rating: number;
  relativeTimeDescription: string;
  text: string;
  recommendationType: string;
}

export default function FacebookReviews({
  slidesPerView,
}: {
  slidesPerView?: number;
}) {
  const carouselRef = React.useRef<SwiperClass>();

  const [facebookData, setFacebookData] = React.useState<any>(null);
  async function fetchFacebookReviews() {
    try {
      const res = await fetch(
        'https://fbrw.reputon.com/app/storefront/widget?shop=helmetking-0001.myshopify.com',
      );
      const data = (await res.json()) as any;
      setFacebookData(data);
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    fetchFacebookReviews();
  }, []);

  return (
    <div className="relative py-10 px-10">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        onSwiper={(swiper) => {
          carouselRef.current = swiper;
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className=""
        breakpoints={{
          320: {
            spaceBetween: 0,
            slidesPerView: 1,
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 30,
            slidesPerView: slidesPerView ?? 3,
          },
        }}
      >
        {facebookData &&
          facebookData?.reviewsData &&
          facebookData?.reviewsData?.reviews &&
          facebookData?.reviewsData?.reviews?.map((r: any, index: number) => {
            return (
              <SwiperSlide key={`facebook-review-${index}`}>
                <Card review={r} />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <button
        onClick={() => carouselRef.current?.slidePrev()}
        className={clsx('absolute left-0 top-1/2 z-10  -translate-y-1/2')}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-90"
        >
          <path
            d="M9.75 4.5L6 8.25L2.25 4.5"
            stroke="#3A3E3E"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        onClick={() => carouselRef.current?.slideNext()}
        className={clsx('absolute right-0 top-1/2 z-10 -translate-y-1/2')}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="-rotate-90"
        >
          <path
            d="M9.75 4.5L6 8.25L2.25 4.5"
            stroke="#3A3E3E"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

function Card({review}: {review: FacebookCustomerReview}) {
  const rating = review.rating;
  const bestRating = 5;
  const [isRevealed, setIsRevealed] = React.useState(false);

  return (
    <article className="single-review--15KFU">
      <div className="top-part--1hwAl relative z-10 mb-8">
        <div className="holder--2wA0e relative z-10 bg-[#fafafb] p-5">
          <div className="mb-4 flex items-center justify-between">
            <div className="stars">
              <div className="flex items-center space-x-2">
                <div className="">
                  <svg fill="#F36B7F" width="18" height="18">
                    <use href="#icon-comment--2b9_1">
                      <g
                        id="icon-comment--2b9_1"
                        width="18"
                        height="18"
                        fillRule="evenodd"
                      >
                        <path
                          fill="inherit"
                          d="M9 14l-3.293 3.293A1 1 0 0 1 4 16.586V14h-.154c-1.337 0-1.822-.14-2.311-.4A2.726 2.726 0 0 1 .4 12.464c-.261-.488-.4-.973-.4-2.309v-6.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 0 1 1.536.4c.488-.261.973-.4 2.309-.4h10.31c1.336 0 1.821.14 2.31.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v6.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 0 1-1.135 1.134c-.489.262-.974.401-2.31.401H9zm0-5l1.454.765a.5.5 0 0 0 .726-.527l-.278-1.62 1.177-1.147a.5.5 0 0 0-.277-.853l-1.626-.236-.728-1.474a.5.5 0 0 0-.896 0l-.728 1.474-1.626.236a.5.5 0 0 0-.277.853l1.177 1.147-.278 1.62a.5.5 0 0 0 .726.527L9 9z"
                        ></path>
                      </g>
                    </use>
                  </svg>
                </div>
                <div className="">
                  <a
                    href="https://www.facebook.com/1607355899494029/reviews"
                    target="_blank"
                    className="text-blue-500"
                  >
                    頭盔王 Helmetking.com
                  </a>
                </div>
                <label className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 585.86 585.7"
                    width="17"
                    height="17"
                  >
                    <use href="#icon-verified--lT02W">
                      <g id="icon-verified--lT02W">
                        <path
                          d="M292.91,0c35.61,0,89.68,76.34,89.68,76.34S474.9,60.5,500.05,85.53C525.23,110.87,509.42,203,509.42,203s76.44,54.17,76.44,89.65c0,35.8-76.44,89.65-76.44,89.65s15.81,92.49-9.37,117.52c-25.15,25.34-117.46,9.5-117.46,9.5s-54.07,76.34-89.68,76.34-89.67-76.34-89.67-76.34S111,525.2,85.78,499.86c-25.15-25-9.38-117.52-9.38-117.52S0,328.49,0,292.69C0,257.21,76.4,203,76.4,203S60.63,110.87,85.78,85.53c25.18-25,117.46-9.19,117.46-9.19S257.34,0,292.91,0Z"
                          className="fill-[#2aa8d7]"
                        ></path>
                        <path
                          d="M289.15,296.22l91-91.34a17.36,17.36,0,0,1,24.55,0l24.54,24.66a17.48,17.48,0,0,1,0,24.65L307.88,375.81a7.18,7.18,0,0,1-1.13,1.33l-24.56,24.65a17.38,17.38,0,0,1-24.66,0L233,377.14a6.78,6.78,0,0,1-1.12-1.33l-63.52-63.7a17.29,17.29,0,0,1,0-24.49L192.89,263a17.37,17.37,0,0,1,24.56,0l48.1,48.16h0l.1.16,4.23,4.3,0-.16,49.17,48.81Z"
                          className="fill-white"
                        ></path>
                      </g>
                    </use>
                  </svg>
                  <span className="hidden">Verified</span>
                </label>
              </div>
            </div>
            <div className="facebook-icon">
              <a
                href="https://maps.google.com/maps?cid=1630611774605658941"
                target="_blank"
                rel="noopener"
                className="img-holder--1erVU logo--2Bixi"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 533.33 530.09"
                  width="20"
                >
                  <use href="#logo-f--2XKAp">
                    <g id="logo-f--2XKAp" height="18">
                      <path
                        className="svg-class-1"
                        fill="#1877f2"
                        d="M533.33,266.67C533.33,119.39,413.94,0,266.67,0S0,119.39,0,266.67c0,133.1,97.52,243.42,225,263.42V343.75H157.29V266.67H225V207.92c0-66.84,39.81-103.75,100.72-103.75,29.18,0,59.7,5.21,59.7,5.21V175H351.79c-33.13,0-43.46,20.56-43.46,41.64v50h74l-11.82,77.08H308.33V530.09c127.49-20,225-130.32,225-263.42"
                      ></path>
                    </g>
                  </use>
                </svg>
              </a>
            </div>
          </div>
          <div
            className="text-block--3sRJY text-hide--3Xl2c text-hidden--hkVn0"
            data-target="show-more-text"
          >
            <div
              data-target="text-holder"
              className={clsx(
                'text-holder--3QC0F overflow-hidden',
                !isRevealed && 'max-h-[80px]',
              )}
            >
              <div data-target="text" className="text-sm leading-snug">
                {review.text}
              </div>
            </div>
            {review.text && review.text?.length >= 100 && (
              <button
                className="show-more--2_Dlp text-sm opacity-50"
                onClick={() => setIsRevealed((state) => !state)}
              >
                {isRevealed ? '顯示較少' : '顯示更多'}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-part--1go2y">
        <div className="user-block--3VyA5">
          <div className="flex space-x-2">
            {/* <div className="column--3UtC7">
              <a
                className="image--3kHpj"
                href="https://maps.google.com/maps?cid=1630611774605658941"
                target="_blank"
                rel="noopener"
              >
                <Image
                  src={review.profilePhotoUrl}
                  width={50}
                  height={50}
                  alt="Profile Pic"
                />
              </a>
            </div> */}
            <div className="column--3UtC7">
              <a
                className="name--qiZGD text-blue-500"
                href={review.authorUrl}
                target="_blank"
                rel="noopener"
              >
                {review.authorName}
              </a>
              <div className="date--LdG74">
                {review.relativeTimeDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function Star({active}: {active: boolean}) {
  return (
    <svg
      viewBox="0 0 1792 1792"
      width="17"
      height="17"
      fill={active ? 'inherit' : '#ccc'}
    >
      <use href="#star--3tDGh">
        <g id="star--3tDGh" fill="inherit" width="17" height="17">
          <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"></path>
        </g>
      </use>
    </svg>
  );
}

function EmptyStar() {
  return (
    <svg viewBox="0 0 1792 1792" width="17" height="17" fill="#ccc">
      <use href="#star--3tDGh">
        <g id="star--3tDGh" fill="inherit" width="17" height="17">
          <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"></path>
        </g>
      </use>
    </svg>
  );
}
