import React from 'react';
import {useMatches, Await, useRouteLoaderData} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperClass} from 'swiper/types';
import {Autoplay} from 'swiper';
import 'swiper/swiper.min.css';
import clsx from 'clsx';
import {Skeleton} from 'components/Skeleton';

interface GoogleCustomerReview {
  authorName: string;
  authorUrl: string;
  profilePhotoUrl: string;
  rating: number;
  relativeTimeDescription: string;
  text: string;
}

export default function GoogleReviews({
  slidesPerView,
}: {
  slidesPerView?: number;
}) {
  const carouselRef = React.useRef<SwiperClass>();
  const [googleData, setGoogleData] = React.useState<any>(null);
  async function fetchGoogleReviews() {
    try {
      const res = await fetch(
        'https://grw.reputon.com/app/storefront/widget?shop=helmetking-0001.myshopify.com',
      );
      const data = (await res.json()) as any;
      setGoogleData(data);
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    fetchGoogleReviews();
  }, []);

  return (
    <div className="relative py-10 px-10">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        onSwiper={(swiper) => {
          carouselRef.current = swiper;
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className=""
        breakpoints={{
          320: {
            spaceBetween: 0,
            slidesPerView: 1,
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 30,
            slidesPerView: slidesPerView ?? 3,
          },
        }}
      >
        {googleData &&
          googleData?.reviewsData &&
          googleData?.reviewsData?.reviews &&
          googleData?.reviewsData?.reviews?.map((r: any, index: number) => {
            return (
              <SwiperSlide key={`google-review-${index}`}>
                <Card review={r} />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <button
        onClick={() => carouselRef.current?.slidePrev()}
        className={clsx('absolute left-0 top-1/2 z-10  -translate-y-1/2')}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-90"
        >
          <path
            d="M9.75 4.5L6 8.25L2.25 4.5"
            stroke="#3A3E3E"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        onClick={() => carouselRef.current?.slideNext()}
        className={clsx('absolute right-0 top-1/2 z-10 -translate-y-1/2')}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="-rotate-90"
        >
          <path
            d="M9.75 4.5L6 8.25L2.25 4.5"
            stroke="#3A3E3E"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

// function GoogleReviews({
//   slidesPerView,
//   data,
// }: {
//   slidesPerView?: number;
//   data: any;
// }) {
//   const carouselRef = React.useRef<SwiperClass>();
//   const [googleData, setGoogleData] = React.useState<any>(null);
//   async function fetchGoogleReviews() {
//     try {
//       const res = await fetch(
//         'https://grw.reputon.com/app/storefront/widget?shop=helmetking-0001.myshopify.com',
//       );
//       const data = (await res.json()) as any;
//       setGoogleData(data);
//     } catch (error) {
//       console.log(error);
//     }
//   }
//   React.useEffect(() => {
//     fetchGoogleReviews();
//   }, []);

//   console.log(googleData);

//   return (
//     <div className="relative py-10 px-10">
//       <Swiper
//         slidesPerView={3}
//         spaceBetween={30}
//         onSwiper={(swiper) => {
//           carouselRef.current = swiper;
//         }}
//         autoplay={{
//           delay: 2500,
//           disableOnInteraction: false,
//         }}
//         modules={[Autoplay]}
//         className=""
//         breakpoints={{
//           320: {
//             spaceBetween: 0,
//             slidesPerView: 1,
//           },
//           768: {
//             spaceBetween: 20,
//             slidesPerView: 2,
//           },
//           1024: {
//             spaceBetween: 30,
//             slidesPerView: slidesPerView ?? 3,
//           },
//         }}
//       >
//         {googleData &&
//           googleData?.reviewsData &&
//           googleData?.reviewsData?.reviews &&
//           googleData?.reviewsData?.reviews?.map((r: any, index: number) => {
//             return (
//               <SwiperSlide key={`google-review-${index}`}>
//                 <Card review={r} />
//               </SwiperSlide>
//             );
//           })}
//       </Swiper>
//       <button
//         onClick={() => carouselRef.current?.slidePrev()}
//         className={clsx('absolute left-0 top-1/2 z-10  -translate-y-1/2')}
//       >
//         <svg
//           width="35"
//           height="35"
//           viewBox="0 0 12 12"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//           className="rotate-90"
//         >
//           <path
//             d="M9.75 4.5L6 8.25L2.25 4.5"
//             stroke="#3A3E3E"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           />
//         </svg>
//       </button>
//       <button
//         onClick={() => carouselRef.current?.slideNext()}
//         className={clsx('absolute right-0 top-1/2 z-10 -translate-y-1/2')}
//       >
//         <svg
//           width="35"
//           height="35"
//           viewBox="0 0 12 12"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//           className="-rotate-90"
//         >
//           <path
//             d="M9.75 4.5L6 8.25L2.25 4.5"
//             stroke="#3A3E3E"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           />
//         </svg>
//       </button>
//     </div>
//   );
// }

function Card({review}: {review: GoogleCustomerReview}) {
  const rating = review.rating;
  const bestRating = 5;
  const [isRevealed, setIsRevealed] = React.useState(false);

  return (
    <article className="single-review--15KFU">
      <div className="top-part--1hwAl relative z-10 mb-8">
        <div className="holder--2wA0e relative z-10 bg-[#fafafb] p-5">
          <div className="mb-4 flex items-center justify-between">
            <div className="stars">
              <div className="flex items-center space-x-2">
                <a
                  href="https://maps.google.com/maps?cid=1630611774605658941"
                  target="_blank"
                  rel="noopener"
                  className="inline-flex items-center fill-[#e7711b] text-[#e7711b]"
                >
                  <Star active={bestRating - rating < 5 && true} />
                  <Star active={bestRating - rating < 4 && true} />
                  <Star active={bestRating - rating < 3 && true} />
                  <Star active={bestRating - rating < 2 && true} />
                  <Star active={bestRating - rating < 1 && true} />
                </a>
                <label className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 585.86 585.7"
                    width="17"
                    height="17"
                  >
                    <use href="#icon-verified--lT02W">
                      <g id="icon-verified--lT02W">
                        <path
                          d="M292.91,0c35.61,0,89.68,76.34,89.68,76.34S474.9,60.5,500.05,85.53C525.23,110.87,509.42,203,509.42,203s76.44,54.17,76.44,89.65c0,35.8-76.44,89.65-76.44,89.65s15.81,92.49-9.37,117.52c-25.15,25.34-117.46,9.5-117.46,9.5s-54.07,76.34-89.68,76.34-89.67-76.34-89.67-76.34S111,525.2,85.78,499.86c-25.15-25-9.38-117.52-9.38-117.52S0,328.49,0,292.69C0,257.21,76.4,203,76.4,203S60.63,110.87,85.78,85.53c25.18-25,117.46-9.19,117.46-9.19S257.34,0,292.91,0Z"
                          className="fill-[#2aa8d7]"
                        ></path>
                        <path
                          d="M289.15,296.22l91-91.34a17.36,17.36,0,0,1,24.55,0l24.54,24.66a17.48,17.48,0,0,1,0,24.65L307.88,375.81a7.18,7.18,0,0,1-1.13,1.33l-24.56,24.65a17.38,17.38,0,0,1-24.66,0L233,377.14a6.78,6.78,0,0,1-1.12-1.33l-63.52-63.7a17.29,17.29,0,0,1,0-24.49L192.89,263a17.37,17.37,0,0,1,24.56,0l48.1,48.16h0l.1.16,4.23,4.3,0-.16,49.17,48.81Z"
                          className="fill-white"
                        ></path>
                      </g>
                    </use>
                  </svg>
                  <span className="hidden">Verified</span>
                </label>
              </div>
            </div>
            <div className="google-icon">
              <a
                href="https://maps.google.com/maps?cid=1630611774605658941"
                target="_blank"
                rel="noopener"
                className="img-holder--1erVU logo--2Bixi"
              >
                <svg viewBox="0 0 512 512" width="17" height="17">
                  <use href="#logo-g--1Zrwh">
                    <g
                      id="logo-g--1Zrwh"
                      className="fill-none"
                      height="44"
                      width="44"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
                        fill="#4285f4"
                      ></path>
                      <path
                        d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
                        fill="#34a853"
                      ></path>
                      <path
                        d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
                        fill="#fbbc05"
                      ></path>
                      <path
                        d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
                        fill="#ea4335"
                      ></path>
                      <path d="M20 20h472v472H20V20z"></path>
                    </g>
                  </use>
                </svg>
              </a>
            </div>
          </div>
          <div
            className="text-block--3sRJY text-hide--3Xl2c text-hidden--hkVn0"
            data-target="show-more-text"
          >
            <div
              data-target="text-holder"
              className={clsx(
                'text-holder--3QC0F overflow-hidden',
                !isRevealed && 'max-h-[80px]',
              )}
            >
              <div data-target="text" className="text-sm leading-snug">
                {review.text}
              </div>
            </div>
            {review.text && review.text?.length >= 100 && (
              <button
                className="show-more--2_Dlp text-sm opacity-50"
                onClick={() => setIsRevealed((state) => !state)}
              >
                {isRevealed ? '顯示較少' : '顯示更多'}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-part--1go2y">
        <div className="user-block--3VyA5">
          <div className="flex space-x-2">
            <div className="column--3UtC7">
              <a
                className="image--3kHpj"
                href="https://maps.google.com/maps?cid=1630611774605658941"
                target="_blank"
                rel="noopener"
              >
                {review.profilePhotoUrl && (
                  <Image
                    data={{
                      url: review.profilePhotoUrl,
                    }}
                    width={50}
                    height={50}
                    alt="Profile Pic"
                  />
                )}
              </a>
            </div>
            <div className="column--3UtC7">
              <a
                className="name--qiZGD"
                href={review.authorUrl}
                target="_blank"
                rel="noopener"
              >
                {review.authorName}
              </a>
              <div className="date--LdG74">
                {review.relativeTimeDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function Star({active}: {active: boolean}) {
  return (
    <svg
      viewBox="0 0 1792 1792"
      width="17"
      height="17"
      fill={active ? 'inherit' : '#ccc'}
    >
      <use href="#star--3tDGh">
        <g id="star--3tDGh" fill="inherit" width="17" height="17">
          <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"></path>
        </g>
      </use>
    </svg>
  );
}

function EmptyStar() {
  return (
    <svg viewBox="0 0 1792 1792" width="17" height="17" fill="#ccc">
      <use href="#star--3tDGh">
        <g id="star--3tDGh" fill="inherit" width="17" height="17">
          <path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"></path>
        </g>
      </use>
    </svg>
  );
}
