import type {SanityModuleLooxReviews} from 'domains/modules';
import {useMatches} from '@remix-run/react';
import Container from 'components/Container';
import React from 'react';
import {I18nLocale} from '~/lib/type';

export default function LooxReviews({
  module,
}: {
  module: SanityModuleLooxReviews;
}) {
  const [root] = useMatches();
  const selectedLocale = root.data.selectedLocale as I18nLocale;

  if (!module.active) {
    return <></>;
  }

  // React.useEffect(() => {
  //   console.log('hi')
  //   const script = document.createElement('script');

  //   script.src = 'https://loox.io/widget/loox.js?shop=helmetking-0001.myshopify.com';

  //   script.async = true;

  //   script.crossOrigin = 'anonymous';

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <div id="looxCarousel" data-show-more="true"></div>
      <script
        async
        src={`//loox.io/widget/loox.js?shop=helmetking-0001.myshopify.com&language=${
          selectedLocale.language === 'ZH_TW' ? 'zh-TW' : 'en'
        }`}
      ></script>
    </>
  );
}
